import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/index.vue'
import Policy from '../views/policy.vue'
import Subscribe from '../views/sub.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/subscribe',
    name: 'subscribe',
    component: Subscribe
  },
  {
    path: '/policy',
    name: 'policy',
    component: Policy
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
